@import '../../../../scss/theme-bootstrap';

.header-gnav-links {
  margin-bottom: 28px;
  width: 100%;
  display: flex;
  flex-direction: column;
  &__title {
    margin-bottom: 13px;
  }
  &__link {
    margin-bottom: 13px;
    display: block;
    width: 100%;
    &.active {
      color: $cr19-text;
    }
  }
  .header-gnav-link {
    position: relative;
  }
}
